<template>
  <v-container fluid>
    <v-row v-if="isResponsive">
      <v-col cols="12">
        <div class="display-flex align-items-center">
          <p class="text-title mon-bold">{{ texts.users.textTitle }}</p>
          <v-spacer />
          <v-btn
            @click="addUser"
            class="button-primary"
            elevation="0"
            icon
            width="40px !important"
            height="40px !important"
          >
            <v-icon size="18px">mdi-plus-thick</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <div class="">
          <v-text-field
            v-model="sSearch"
            type="text"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular"
            :placeholder="texts.users.textSearch"
            persistent-placeholder
            clearable
            append-icon="mdi-magnify"
            maxLength="100"
          ></v-text-field>
          <v-select
            v-model="userStatus"
            :label="texts.users.textUserStatus"
            :placeholder="texts.users.textUserStatus"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mt-4"
            :items="aUserStatus"
            item-text="text"
            item-value="value"
            clearable
          ></v-select>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        md="3"
      >
        <p class="text-title mon-bold">{{ texts.users.textTitle }}</p>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <div class="convert-display-block display-flex align-items-center height-100">
          <v-text-field
            v-model="sSearch"
            type="text"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-2"
            :placeholder="texts.users.textSearch"
            persistent-placeholder
            clearable
            append-icon="mdi-magnify"
            maxLength="100"
          ></v-text-field>
          <v-select
            v-model="userStatus"
            :label="texts.users.textUserStatus"
            :placeholder="texts.users.textUserStatus"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-2 saltoLineaResponsive"
            :items="aUserStatus"
            item-text="text"
            item-value="value"
            clearable
          ></v-select>
          <v-btn
            @click="addUser"
            class="button-add mon-bold saltoLineaResponsive"
            elevation="0"
          >
            {{ texts.users.textButtonAdd }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UsersSearchbarLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      sSearch: "",
      userStatus: "",
      aUserStatus: [],
      isResponsive: false,
    };
  },
  beforeMount() {
    this.$store.commit("setSearch", "");
    this.$store.commit("setUserStatus", "");
    this.aUserStatus = [
      {
        text: this.selectLanguage == "sp" ? "Activo" : "Active",
        value: true,
      },
      {
        text: this.selectLanguage == "sp" ? "Inactivo" : "Inactive",
        value: false,
      },
    ];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    addUser: function () {
      this.$store.commit("setDialogAddUser", true);
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.aUserStatus = [
          {
            text: this.selectLanguage == "sp" ? "Activo" : "Active",
            value: true,
          },
          {
            text: this.selectLanguage == "sp" ? "Inactivo" : "Inactive",
            value: false,
          },
        ];
      }
    },
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 300),
    userStatus: function () {
      this.userStatus == null
        ? this.$store.commit("setUserStatus", "")
        : this.$store.commit("setUserStatus", this.userStatus);
    },
  },
};
</script>

<style scoped>
.button-add {
  height: 40px !important;
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.text-title {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  .convert-display-block {
    display: block;
  }
  .button-add {
    margin-top: 8px;
    width: 100%;
  }
}

@media (max-width: 960px) {
  .saltoLineaResponsive {
    margin-top: 16px;
  }
}
</style>